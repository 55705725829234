'use client';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useTranslations } from 'next-intl';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { signIn } from 'next-auth/react';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import NextLoadingButton from 'components/button/NextLoadingButton';
import { AuthErrors } from '@cp/shared/lib/error';
import Image from 'next/image';
import TextField from '@cp/ui/components/form/TextField';
import { useMediaQuery } from '@mui/material';
import withSuspense from '@cp/ui/components/WithSuspense';
import { useRouter } from 'navigation';
import { trpc } from 'trpc/client';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { startAuthentication } from '@simplewebauthn/browser';
import { AppContext } from 'context/AppProvider';

type FormInputs = {
  contact: string;
};

const SigninPage = () => {
  const matches = useMediaQuery('(min-height: 750px)');

  const e = useTranslations('Errors');
  const t = useTranslations('SigninPage');
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = useSearchParams();
  const router = useRouter();
  const queryClient = useQueryClient();
  const { isMobileSafari } = useContext(AppContext);

  const callbackUrl = searchParams?.get('callbackUrl');
  const contact = searchParams?.get('contact');
  const error = searchParams?.get('error');

  const methods = useForm<FormInputs>({
    defaultValues: {
      contact: '',
    },
    resolver: yupResolver<FormInputs>(
      Yup.object().shape({
        contact: Yup.string()
          .required(e('contact-is-required'))
          .test('contact', e('invalid-contact'), (value) => {
            if (!value) return false;
            const emailValid = Yup.string().email().isValidSync(value);
            const phoneValid = /^\+?[1-9]\d{1,14}$/.test(value);
            return emailValid || phoneValid;
          }),
      }),
    ),
  });

  const {
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (contact) {
      setValue('contact', contact, { shouldDirty: true });
    }
  }, [contact, setValue]);

  const signinPasscode = async (identifier: string) => {
    const response = await signIn('passcode', {
      redirect: false,
      identifier,
    });

    if (response?.error === 'NoCode') {
      if (callbackUrl) {
        router.push(
          `/auth/verify-request?identifier=${encodeURIComponent(
            identifier,
          )}&callbackUrl=${encodeURIComponent(callbackUrl)}`,
        );
      } else {
        router.push(`/auth/verify-request?identifier=${encodeURIComponent(identifier)}`);
      }
    } else {
      router.push(`/auth/error?error=${response?.error}`);
    }
  };

  const authenticateWebauthn = async (identifier: string) => {
    const queryKey = getQueryKey(trpc.auth.webauthn.authenticate, undefined, 'query');
    const options = await queryClient.fetchQuery({
      queryKey,
      queryFn: async () => {
        const params = new URLSearchParams({
          input: JSON.stringify({
            json: {
              identifier,
            },
          }),
        }).toString();
        const response = await fetch(`/api/trpc/auth.webauthn.authenticate?${params}`, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Failed to fetch webauthn options');
        } else {
          const result = await response.json();
          return result.result.data.json.options;
        }
      },
    });

    return options;
  };

  const signinWebauthn = async (identifier: string) => {
    const options = await authenticateWebauthn(identifier);

    const assertion = await startAuthentication({
      optionsJSON: options,
      // useBrowserAutofill: true,
    });

    const response = await signIn('webauthn', {
      redirect: false,
      assertion: JSON.stringify(assertion),
    });

    if (response?.error) {
      throw new Error(response?.error);
    } else {
      if (callbackUrl) {
        router.push(callbackUrl);
      } else {
        router.push('/');
      }
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = async ({ contact }) => {
    setIsLoading(true);

    const identifier = contact.trim().toLowerCase();

    try {
      if (isMobileSafari) {
        throw new Error('UnsupportedBrowser');
      }

      await signinWebauthn(identifier);
    } catch (error) {
      await signinPasscode(identifier);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
    >
      <FormProvider {...methods}>
        <Stack spacing={4} width="100%">
          {error && (
            <Alert severity="error" sx={{ marginTop: '1em' }}>
              {AuthErrors[error]}
            </Alert>
          )}
          <Typography variant="h4" textAlign="center">
            {t('instructions')}
          </Typography>
          <Box px={{ sm: 8, lg: 4 }}>
            <TextField
              name="contact"
              placeholder={t('your-contact')}
              autoComplete="contact"
              disabled={isLoading}
            />
          </Box>
          <Box
            sx={{ display: 'flex', justifyContent: 'end', marginTop: '2em' }}
            px={{ sm: 8, lg: 4 }}
          >
            <NextLoadingButton type="submit" disabled={!isDirty || isLoading} loading={isLoading} />
          </Box>
        </Stack>
      </FormProvider>
      {matches && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            width: '100%',
            position: 'absolute',
            left: 0,
            bottom: 44,
          }}
        >
          <Image
            src="/assets/bear-wave.svg"
            alt="Clarity Pediatrics logo"
            placeholder="blur"
            blurDataURL={'/assets/bear-wave.svg'}
            width={185}
            height={133}
          />
        </Box>
      )}
    </Box>
  );
};

export default withSuspense(SigninPage);
