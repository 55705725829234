import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import { useTranslations } from 'next-intl';
import { MouseEventHandler } from 'react';

type Props = {
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  label?: string;
  onClick?: MouseEventHandler;
};

const NextLoadingButton = ({ type, disabled, loading, label, onClick }: Props) => {
  const t = useTranslations('Common');

  return (
    <Button
      type={type}
      variant="contained"
      color="primary"
      size="large"
      disabled={disabled}
      loading={loading}
      endIcon={<ArrowForwardIcon />}
      onClick={onClick}
      sx={{
        textTransform: 'none',
        '& svg': {
          transform: loading ? 'scale(1.8)' : 'scale(1)',
        },
      }}
    >
      <span>{label || t('next')}</span>
    </Button>
  );
};

export default NextLoadingButton;
